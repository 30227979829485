<template>
    <header class="kiwi-header-section">
        <v-container>
            <v-row>
                <v-col cols="3" lg="2">
                    <router-link to="/"><img src="@/assets/logo.svg" alt=""></router-link>
                </v-col>
                <v-col cols="5" lg="7">
                    <nav>
                        <div class="d-flex justify-center">
                            <v-btn to="/product" class="text-capitalize" variant="text">Products</v-btn>
                            <v-btn to="/feature" class="text-capitalize" variant="text">Features</v-btn>
                            <v-btn to="/pricing" class="text-capitalize" variant="text">Pricing</v-btn>
                            <v-btn to="/blog" class="text-capitalize" variant="text">Blog</v-btn>
                        </div>
                    </nav>
                </v-col>
                <v-col cols="4" lg="3" class="text-right">
                    <v-btn href="/login" class="kiwi-btn kiwi-border-primary text-body-1 kiwi-text-primary primary mr-4 v-btn--variant-flat" variant="outlined" rounded="xl" size="large" min-width="116px" height="44px" :ripple="false">Sign In</v-btn>
                    <v-btn href="/register" class="kiwi-btn kiwi-bg-primary text-body-1 kiwi-text-white" rounded="xl" size="large" variant="flat" min-width="116px" height="44px" :ripple="false">Sign Up</v-btn>
                </v-col>
            </v-row>
        </v-container>
    </header>
</template>