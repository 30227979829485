import { createRouter, createWebHistory } from 'vue-router'
import protected_routes from '././protected_routes'
import {AuthTokenService} from '@/handler/authService';
import guest_routes from "@/router/guest_routes";
import web_routes from "@/router/web_routes";
import institute_route from "@/router/institute_route";
import agent_routes from "@/router/agent_routes"
import { useInstituteStore } from '@/stores/institute/institute-store';
import { getActivePinia } from 'pinia';
import { useAuthStore } from '@/stores/auth/authStore';

const ROUTE_LIST = [...protected_routes, ...web_routes, ...guest_routes, ...institute_route, ...agent_routes]
const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: ROUTE_LIST
})

router.beforeEach((to, from, next) => {
  const pinia = getActivePinia();
  const instituteStore = useInstituteStore();
  const userStore = useAuthStore();
  const requiresAuth = to.meta?.requiresAuth;
  const isAuthenticated = AuthTokenService.isAuthenticated();
  if (to.query.override_access) {
    if(isAuthenticated){
      AuthTokenService.logout();
      AuthTokenService.setTokens(to.query.access_token, to.query.refresh_token)
    }else{
      AuthTokenService.setTokens(to.query.access_token, to.query.refresh_token)
      next({ name: 'dashboard' });
    }
  }
  if(to.meta.web) {
      if(instituteStore.isDefault) {
        next()
      }else {
        next({ name: 'login' });
      }
  }else if (requiresAuth && !isAuthenticated) {
    next({ name: 'login' });
  } else if (isAuthenticated) {
    if(to.meta.requiresAuth === false) {
      next({ name: 'dashboard' });
    }else if(userStore.userType && to.meta.user_type) {
      if(to.meta.user_type === userStore.userType) {
        next();
      }else {
        let defaultRoute  = 'dashboard'
        if(userStore.isInstitute) {
          defaultRoute  = 'ins_student'
        }else if(userStore.isAgent) {
          defaultRoute = 'agent_voucher'
        }
        next({ name: defaultRoute });
      }
    }else {
      next() //allow if no user type found, it will watch route after user available
    }
  } else {
    next();
  }
});

export default router
