import Home from '@/pages/Website/Pages/Home.vue'
import Blog from '@/pages/Website/Pages/Blog.vue'
import BlogPost from '@/pages/Website/Pages/SingleBlog.vue'
import Pricing from '@/pages/Website/Pages/Pricing.vue'
import Feature from '@/pages/Website/Pages/Feature.vue'
import Product from '@/pages/Website/Pages/Product.vue'
import CommingSoon from '@/pages/Website/Pages/ComingSoon.vue'
import Terms from '@/pages/Website/Pages/terms.vue'
import Privacy from '@/pages/Website/Pages/privacy.vue'

const webroutes = [
{
  path: '/',
  name: 'home',
  component: Home,
  meta: {
    requiresAuth: false,
  },
},
{
  path: '/blog',
    name: 'blog',
  component: Blog,
  props: true
},
{
  path: '/blog/:slug',
    name: 'blogPost',
  component: BlogPost,
  props: true
},
{
  path: '/pricing',
    name: 'pricing',
  component: Pricing,
},
{
  path: '/feature',
    name: 'feature',
  component: Feature,
},
{
  path: '/product',
    name: 'product',
  component: Product,
},
{
  path: '/terms-and-conditions',
    name: 'terms',
  component: Terms,
},
{
  path: '/privacy',
  component: Privacy,
},
{
  path: '/comming-soon',
    name: 'CommingSoon',
  component: CommingSoon,
},
];
webroutes.forEach(r => {
  const meta =(r.meta || {})
  r.meta = {
    ...{...meta, web: true},
    requiresAuth: false
  }
})
export default webroutes;
