<template>
    <div class="d-flex flex-wrap kiwi-voucher-checkout-grid">
        <div class="kiwi-crate-voucher-form-wrapper">
            <v-sheet
                class="kiwi-mock-test kiwi-bg-white kiwi-test-black pa-0 kiwi-radius-8 overflow-hidden border kiwi-border-light-grey-3 fill-height"
                variant="flat"
            >
                <v-sheet class="kiwi-mock-test-header py-3 px-4 d-flex flex-wrap" variant="flat">
                    <h1 class="kiwi-fs-20 font-weight-bold">Create Voucher</h1>
                </v-sheet>
                <v-divider />
                <div class="pa-3 font-weight-medium">
                    <v-sheet
                        class="py-3 px-4 kiwi-radius-4 d-none d-md-block"
                        color="#F7F8F9"
                        variant="flat"
                    >
                        <v-row>
                            <v-col 
                                cols="12"
                                md="3"
                            >
                                <span class="kiwi-fs-14 kiwi-text-black-3">No of Vouchers</span>
                            </v-col>
                            <v-col 
                                cols="12"
                                md="5"
                            >
                                <span class="kiwi-fs-14 kiwi-text-black-3">Package</span>
                            </v-col>
                            <v-col 
                                cols="12"
                                md="3"
                            >
                                <span class="kiwi-fs-14 kiwi-text-black-3">Amount (NPR)</span>
                            </v-col>
                        </v-row>
                    </v-sheet>
                    <v-sheet
                        class="py-3 px-4 kiwi-radius-4 mt-3"
                        color="#F7F8F9"
                        variant="flat"
                        v-for="(data, i) in createdVoucher"
                        :key="i"
                    >
                        <v-row>
                            <v-col cols="12" md="12" class="kiwi-order-sm-2">
                                <v-row>
                                    <v-col 
                                        cols="12"
                                        md="3"
                                    >
                                        <label class="kiwi-form-label d-block d-md-none mb-2">No of Vouchers</label>
                                        <v-text-field
                                            v-model.number="data.quantity"
                                            type="number"
                                            class="kiwi-form-input kiwi-radius-4 kiwi-bg-white"
                                            max-height="48"
                                            density="comfortable"
                                            variant="outlined"
                                            placeholder="1"
                                            required
                                            hide-details
                                            @update:modelValue="calculatePrice(i)"
                                        ></v-text-field>
                                    </v-col>
                                    <v-col 
                                        cols="12"
                                        md="5"
                                    >
                                        <label class="kiwi-form-label d-block d-md-none mb-2">Package</label>
                                        <v-select
                                            v-model="data.package"
                                            :items="availablePackages"
                                            class="kiwi-form-input kiwi-radius-4 kiwi-bg-white"
                                            max-height="48"
                                            density="comfortable"
                                            variant="outlined"
                                            placeholder="Select package"
                                            required
                                            hide-details
                                            @update:modelValue="calculatePrice(i)"
                                        ></v-select>
                                    </v-col>
                                    <v-col 
                                        cols="12"
                                        md="3"
                                    >
                                        <label class="kiwi-form-label d-block d-md-none mb-2">Amount (NPR)</label>
                                        <v-text-field
                                            v-model.number="data.price"
                                            class="kiwi-form-input kiwi-bg-white kiwi-radius-4 full-opacity-strict"
                                            max-height="48"
                                            density="comfortable"
                                            variant="flat"
                                            required
                                            hide-details
                                            disabled="true"
                                        ></v-text-field>
                                    </v-col>
                                    <v-col cols="12" md="1" class="d-flex align-center pa-0 justify-end justify-md-start kiwi-order-sm-1">
                                        <v-btn icon
                                            width="40"
                                            height="40"
                                            variant="flat"
                                            rounded="0"
                                            color="transparent"
                                            :ripple="false"
                                            class="disable-pseudo-attribute"
                                            v-if="i > 0"
                                            @click.prevent="removeVoucherFromCart(i)"
                                        >
                                            <img src="/assets/delete-02.svg" />
                                        </v-btn>
                                    </v-col>
                                </v-row>
                            </v-col>
                            <v-col cols="12" md="12" class="d-flex align-center kiwi-order-sm-3" v-if="createdVoucher[i]?.name">
                                <v-icon class="mdi mdi-circle-small" /><span class="kiwi-fs-12">Note: For {{createdVoucher[i]?.name}}, Cost Per Voucher is <span class="kiwi-text-primary">NPR. {{createdVoucher[i]?.defaultPrice}}</span></span>
                            </v-col>
                        </v-row>
                    </v-sheet>

                    <v-btn class="kiwi-border-primary kiwi-text-primary kiwi-radius-4 text-none mt-4" height="48" variant="outlined" @click.prevent="addVoucherCart">
                        <v-icon class="mdi mdi-plus-circle-outline mr-2" />Add Voucher
                    </v-btn>
                </div>
            </v-sheet>
        </div>
        <div class="kiwi-crate-voucher-summary">
            <v-sheet
                class="kiwi-mock-test kiwi-bg-white kiwi-test-black pa-0 kiwi-radius-8 overflow-hidden border kiwi-border-light-grey-3"
            >
                <v-sheet class="kiwi-mock-test-header py-3 px-4 d-flex flex-wrap" variant="flat">
                    <h1 class="kiwi-fs-20 font-weight-bold">Order Summary</h1>
                    <v-divider class="mt-4" />
                    <v-table density="comfortable" class="custom-table plain mt-4 checkout-table w-100">
                        <tbody>
                            <tr>
                                <td>Gross Amount</td>
                                <td>
                                    <span class="d-block text-right">
                                        NPR. {{calcSummary?.sub_total || 0}}
                                    </span>
                                </td>
                            </tr>
                            <tr>
                                <td>Discount Amount</td>
                                <td>
                                    <v-tooltip :text="calcSummary?.discount_message || 'Fixed discount of 0'" location="top">
                                        <template v-slot:activator="{ props }">
                                            <span class="d-block text-right" v-bind="props">
                                                - NPR. {{calcSummary?.discount || 0}}
                                            </span>
                                        </template>
                                    </v-tooltip>
                                </td>
                            </tr>
                            <tr>
                                <td>Sub-total</td>
                                <td>
                                    <span class="d-block text-right">
                                        NPR. {{calcSummary?.total || 0}}
                                    </span>
                                </td>
                            </tr>
                            <tr>
                                <td class="pb-2" style="border-bottom: 1px dashed #E2E6E4 !important;">VAT(13%)</td>
                                <td class="pb-2" style="border-bottom: 1px dashed #E2E6E4 !important;">
                                    <span class="d-block text-right">
                                        NPR. {{calcSummary?.vat || 0}}
                                    </span>
                                </td>
                            </tr>
                            <tr>
                                <td class="pt-2"><span class="font-weight-bold">Total</span></td>
                                <td class="pt-2">
                                    <span class="d-block text-right font-weight-bold">
                                        <!-- {{ $route.query.promo === 'true' ? 'NPR. 63.00' : 'NPR. 105.00' }} -->
                                        NPR. <span class="kiwi-fs-20 font-weight-bold">{{parseFloat(calcSummary?.grand_total?.toFixed(2)) || 0}}</span>
                                    </span>
                                </td>
                            </tr>
                        </tbody>
                    </v-table>
                    <v-btn 
                        block 
                        class="kiwi-radius-4 mt-6 text-none" 
                        height="48px"
                        variant="flat"
                        :disabled="!enableCheckoutBtn"
                        :class="enableCheckoutBtn ? 'kiwi-bg-primary kiwi-text-white' : 'kiwi-bg-disabled kiwi-button-disable kiwi-text-black'"
                        @click="continueCartProcessing"
                    >Next</v-btn>
                </v-sheet>
            </v-sheet>
        </div>
    </div>
</template>

<script setup>
import {ref, onMounted, computed, watch} from 'vue'
import { useAgentVoucherStore } from "@/stores/institute/agent-voucher-store";
import {useShopStore} from '@/stores/institute/institute-shop-store';
import { usePackageStore } from '@/stores/package-store';
import { useAuthStore } from "@/stores/auth/authStore";
import { storeToRefs } from "pinia";
import {useRouter} from 'vue-router';
import { clamp } from '@vueuse/core';
const userStore = useAuthStore()
const router = useRouter()
const enableCheckoutBtn = ref(false)
const validation = ref([])
const shopStore = useShopStore();

const {packages, calcSummary, createCartResponse, transaction_uuid} = storeToRefs(shopStore)

const defaultData = {
    quantity: null,
    package: null,
    price: 0,
    name: null,
    defaultPrice: null,
}

const selectPackageIndex = ref(null)

const createdVoucher = ref([
    {...defaultData}
])

const calculatePrice = (i) => {
    enableCheckoutBtn.value = false
    selectPackageIndex.value = i
    const currectVoucherObj =  createdVoucher.value[i]
    currectVoucherObj.quantity <= 0 ? currectVoucherObj.quantity = 0 : ''
    //currectVoucherObj.package === 6 ? currectVoucherObj.quantity = 1 : ''
    currectVoucherObj.price = currectVoucherObj.quantity * packages.value.filter(p => p.id === createdVoucher.value[i].package)[0].price || 0
    currectVoucherObj.name = packages.value.filter(p => p.id === createdVoucher.value[i].package)[0].name
    currectVoucherObj.defaultPrice = packages.value.filter(p => p.id === createdVoucher.value[i].package)[0].price
    if(currectVoucherObj.quantity >= 0) {
        shopStore.getCartCalculation({cart_items: [...createdVoucher.value.map((cv) => {return {package: cv.package, quantity: cv.quantity}})]})
        .catch(err => {
            validation.value = err?.response?.data || []
        })
    }
}

const removeVoucherFromCart = (i) => {
    createdVoucher.value.splice(i, 1)
    shopStore.getCartCalculation({cart_items: [...createdVoucher.value.map((cv) => {return {package: cv.package, quantity: cv.quantity}})]})
}

const addVoucherCart = () => {
    createdVoucher.value.push({...defaultData})   
}

const availablePackages = computed(() => {
    const selectedPackages = createdVoucher.value.map(v => v.package)
    const data = packages.value.map(p => ({title: p.day !== null ? `${p.name}(${p.day} D)` : p.name, value: p.id, disabled: selectedPackages.includes(p.id)}))
    return data;
})

const continueCartProcessing = () => {
    shopStore.createCart({...calcSummary.value, cart_items: [...createdVoucher.value.map((cv) => {return {package: cv.package, quantity: cv.quantity}})]})
    .then(res => {
        setTimeout(() => {
            if(transaction_uuid.value) {
                if(userStore.isInstitute) {
                    router.push({name: 'ins_checkout', params: {uuid: transaction_uuid.value}})
                } else if(userStore.isAgent) {
                    router.push({name: 'agent_checkout', params: {uuid: transaction_uuid.value}})
                }

            }
        }, 300)
    }).catch(err => {
        console.log(err.response.data)
    })
}


watch(calcSummary, (newVal, oldVal) => {
    if(newVal) {
        enableCheckoutBtn.value = true
    }
})
watch(createdVoucher, (newVal, oldVal) => {
    if(newVal.price === 0) {
        calcSummary.value = {}
    }
})
onMounted(() => {
    shopStore.getPackages()
    calcSummary.value = {}
    shopStore.calcSummary.value = {}
})
// const grossSum = () => {
//     return createdVoucher.value.reduce((sum, item) => sum + item.price, 0)
// }
</script>

<style lang="scss">
.kiwi-voucher-checkout-grid {
    margin: -8px -12px;
    .kiwi-crate-voucher-form-wrapper, .kiwi-crate-voucher-summary {
        max-width: 100%;
        flex: 0 0 100%;
        padding: 8px 12px;
    }

    @media screen and (min-width: 1280px) {
        .kiwi-crate-voucher-summary {
            max-width: 500px;
            flex: 0 0 500px;
        }
        .kiwi-crate-voucher-form-wrapper {
            max-width: calc(100% - 500px);
            flex: 0 0 calc(100% - 500px);
        }
    }
}
</style>