<template>
    <v-sheet class="kiwi-bg-transparent kiwi-checkout-page" variant="flat" v-if="!isProcessing">
        <v-row class="justify-center">
            <v-col cols="12" md="7" lg="8" xl="9" v-if="institute.$hasFeature('payment-gateways') || !user.isStudent">
                <v-card class="kiwi-bg-white kiwi-radius-8 overflow-hidden h-100" varaint="flat" elevation="0">
                    <article class="pa-4">
                        <h2 class="font-weight-bold kiwi-fs-18">Choose Payment Method</h2>
                    </article>
                    <v-divider />
                    <section class="pa-4">
                        <div class="d-flex align-center">
                            <!-- <v-badge
                                class="kiwi-text-white mr-2"
                                color="primary"
                                content="1"
                                inline
                            ></v-badge> -->
                            <h3 class="kiwi-fs-18 font-weight-semi-bold kiwi-fs-14">Pay directly with Local Payment Method</h3>
                        </div>
                        <v-row class="mt-3">
                            <v-col cols="12" md="12" v-if="transactionData.currency === 'NPR'">
                                <div class="button-wrapper cursor-pointer esewa kiwi-bg-esewa-btn d-flex flex-wrap align-center justify-center py-4 py-md-6 px-2 kiwi-radius-8 h-100" @click="ePayment('esewa')">
                                    <img src="/assets/esewa.svg" class="payment-icon" alt="esewa">
                                </div>
                            </v-col>
                            <v-col cols="12" md="12" v-if="transactionData.currency === 'NPR'">
                                <div class="button-wrapper cursor-pointer fonepay kiwi-bg-fonepay-btn d-flex flex-wrap align-center justify-center py-4 py-md-6 px-2 kiwi-radius-8 h-100" @click="ePayment('fonepay')">
                                    <img src="/assets/fonepay_payments_fatafat 1.svg" class="payment-icon" alt="fonepay">
                                </div>
                            </v-col>
                            <v-col cols="12" md="12" v-if="transactionData.currency !== 'NPR'">
                                <div class="button-wrapper cursor-pointer fonepay kiwi-bg-light-grey-4 d-flex flex-wrap align-center justify-center py-4 py-md-6 px-2 kiwi-radius-8 h-100" @click="ePayment('stripe')" style="background-color: #d3e5f3 !important;">
                                    <img src="/assets/main-qimg-ad4a8ff83af8b89d3ceaa023e5ff3269.webp" class="payment-icon" alt="stripe">
                                </div>
                            </v-col>
                        </v-row>
                    </section>
                </v-card>
            </v-col>
            <v-col cols="12" md="5" lg="4" xl="3">
                <v-card class="kiwi-bg-white kiwi-radius-8 overflow-hidden" varaint="flat" elevation="0">
                    <article class="py-4 px-4">
                        <h2 class="font-weight-bold kiwi-fs-18">Order Summary</h2>
                    </article>
                    <v-divider />
                    <v-table density="comfortable" class="custom-table plain pb-4 checkout-table">
                        <tbody>
                            <tr v-if="$route.name !== 'agent_checkout' && $route.name !== 'ins_checkout'">
                                <td>Selected Plan</td>
                                <td>
                                    <span class="d-block text-right">
                                        <v-chip label size="small" class="kiwi-border-orange-2 kiwi-bg-light-orange kiwi-text-orange-2 kiwi-fs-14 font-weight-semi-bold" variant="outlined">{{ transactionData?.package_data?.name }}</v-chip>
                                    </span>
                                </td>
                            </tr>
                            <tr>
                                <td>Gross Amount</td>
                                <td>
                                    <span class="d-block text-right">
                                        {{transactionData.currency}}. {{ transactionData.gross }}
                                    </span>
                                </td>
                            </tr>
                            <tr v-if="transactionData?.discount_data?.[0]">
                                <td style="border-bottom: 1px dashed #E2E6E4 !important;" v-if="$route.name !== 'agent_checkout'">{{ transactionData.discount_data[transactionData.discount_data.length - 1].discount_type == 'referral' ? "Referral" : "Promocode" }} Discount</td>
                                <td style="border-bottom: 1px dashed #E2E6E4 !important;" v-else>Discount Amount</td>
                                <td style="border-bottom: 1px dashed #E2E6E4 !important;">
                                    <span class="d-block text-right">
                                        - {{transactionData.currency}}. {{ transactionData.discount }}
                                    </span>
                                </td>
                            </tr>
                            <tr v-if="transactionData?.discount_data?.[0]">
                                <td>Subtotal</td>
                                <td>
                                    <span class="d-block text-right">
                                        {{transactionData.currency}}. {{ transactionData.total }}
                                    </span>
                                </td>
                            </tr>
                            <tr v-if="transactionData?.vat">
                                <td style="border-bottom: 1px dashed #E2E6E4 !important;">VAT(13%)</td>
                                <td style="border-bottom: 1px dashed #E2E6E4 !important;">
                                    <span class="d-block text-right">
                                        {{transactionData.currency}}. {{ transactionData.vat }}
                                    </span>
                                </td>
                            </tr>
                            <tr>
                                <td><span class="font-weight-bold">Total</span></td>
                                <td>
                                    <span class="d-block text-right font-weight-bold">
                                        <!-- {{ $route.query.promo === 'true' ? '{{transactionData.currency}}. 63.00' : '{{transactionData.currency}}. 105.00' }} -->
                                        {{transactionData.currency}}. {{ transactionData.payable_amount }}
                                    </span>
                                </td>
                            </tr>
                            <tr>
                                <td colspan="2" style="border-top: 1px dashed #E2E6E4 !important;">
                                    <v-btn 
                                        v-if="institute.$hasFeature('promo') && !isPromoApplied"
                                        block 
                                        variant="outlined" 
                                        class="kiwi-border-light-grey-3 text-none my-3 font-weight-semi-bold py-6"
                                        rounded="xl"
                                        @click.prevent="apply('promo')"
                                    >
                                        <img src="/assets/discount.svg" class="mr-2">
                                        <span>Have a Promo Code? Apply Here</span>
                                    </v-btn>
                                    <v-btn 
                                        block 
                                        variant="outlined" 
                                        class="kiwi-border-light-grey-3 text-none my-3 font-weight-semi-bold py-6"
                                        rounded="xl"
                                        v-if="institute.$hasFeature('voucher')"
                                        @click.prevent="apply('voucher')"
                                    >
                                        <img src="/assets/coupon-02.svg" class="mr-2">
                                        <span>Have a Voucher Code? Apply here</span>
                                    </v-btn>
                                </td>
                            </tr>
                        </tbody>
                    </v-table>
                </v-card>
            </v-col>
        </v-row>

        <Promo v-if="openPromo" @closeModal="closePromo" />
        <Voucher v-if="openVoucher" @closeModal="closeVoucher" />
    </v-sheet>
    <PromptDialog
      v-bind="{ content: 'Processing..', progress: true }"
      v-if="isLoading"
    />
    <PromptDialog
      v-bind="{ content: 'Loading..', progress: true }"
      v-if="isProcessing"
    />
</template>

<script setup>
import Promo from '@/components/Packages/Promo.vue'
import Voucher from '@/components/Packages/Voucher.vue'
import { onMounted, ref, computed } from 'vue'
import { useRoute } from 'vue-router';
import { usePackageStore } from '@/stores/package-store';
import { storeToRefs } from 'pinia';
import { usePaymentStore } from '@/stores/payment-store';
import PromptDialog from "@/components/Prompt/PromptDialog.vue";
import { useInstituteStore } from '@/stores/institute/institute-store';
import { useAuthStore } from '@/stores/auth/authStore'
const institute = useInstituteStore()
const user = useAuthStore()

const openPromo = ref(false)
const openVoucher = ref(false)
const route = useRoute()
const isLoading = ref(false)
const isProcessing = ref(true)

const packageData = usePackageStore()
const { getTransaction } = packageData
const { transactionData } = storeToRefs(packageData)

const payment = usePaymentStore()
const { esewaPayment, fonepayPayment, stripePayment } = payment

const isPromoApplied = computed(() => {
    if (transactionData.value && Array.isArray(transactionData.value.discount_data) && transactionData.value.discount_data.length > 0) {
        const lastDiscount = transactionData.value.discount_data[transactionData.value.discount_data.length - 1];
        
        if (lastDiscount.discount_type === 'promo') {
        return true;
        }
    }
    return false;
})

const ePayment = (type) => {
    isLoading.value = true
    if(type === 'esewa'){
      esewaPayment(transactionData.value.uuid, closePrompt)
    }
    if(type === 'fonepay'){
        fonepayPayment(transactionData.value.uuid, closePrompt)
    }
    if(type === 'stripe'){
        stripePayment(transactionData.value.uuid, closePrompt)
    }
}

const closePrompt = () => {
    isLoading.value = false
}

const apply = (type) => {
    type === 'promo' ? openPromo.value = true : openVoucher.value = true;
}

const closePromo = () => {
    openPromo.value = false;
}

const closeVoucher = () => {
    openVoucher.value = false;
}

onMounted(() => {
    getTransaction(route.params.uuid)
    setTimeout(() => {
        isProcessing.value = true
        if(transactionData.value) {
            isProcessing.value = false
        }
    }, 500)
})
</script>

<style>

</style>