function removeTags(htmlString) {
    // Regular expression to match HTML tags
    var regex = /(<([^>]+)>)/ig;
    // Replace HTML tags with an empty string
    return (htmlString || '').replace(regex, "");
}
function removeHtmlWithNewlineHint(htmlString) {
    let withNewlines = htmlString.replace(/<\/p>/g, '</p>\n');
    // Step 2: Remove all other HTML tags
    let processedString = withNewlines.replace(/<\/?[^>]+(>|$)/g, '');
    // Step 3: Trim extra spaces or newlines (optional)
    processedString = processedString.trim();
    const result = processedString
    const lines = result.split('\n');
    const lineItems = [];
    lines.forEach(l => {
      lineItems.push(l)
      lineItems.push("")
    })
    const tokens = lineItems.flatMap(line => line.split(' '));
    return tokens
  }
function mockPromise (status = true) {
    return new Promise((resolve) => resolve(status))
}
function limitString (text, count = 100) {
    text = text || ''
    return text.slice(0, count) + (text.length > count ? "..." : "")
}
function downloadResponse (content, fileName)
{
        // Create a blob URL for the CSV file
    const url = window.URL.createObjectURL(new Blob([content]));

    // Create a link element to trigger the download
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `${fileName}.csv`); // Set the filename for the download

    // Append the link to the body and trigger the download
    document.body.appendChild(link);
    link.click();

    // Clean up by removing the link and revoking the blob URL
    document.body.removeChild(link);
    window.URL.revokeObjectURL(url);

}
function convertMinutesToHoursAndMinutes(minutes) {
  if (!minutes) return '0 hrs 0 mins';
  const hours = Math.floor(minutes / 60);
  const remainingMinutes = minutes % 60;
  return `${hours} hrs ${remainingMinutes} mins`;
}
function numRound (value, round = 2)
{
  return value ? parseFloat(value.toFixed(2)) : value
}
export {
    removeTags,
    mockPromise,
    limitString,
    downloadResponse,
    removeHtmlWithNewlineHint,
    convertMinutesToHoursAndMinutes,
    numRound
}
