<template>
  <div>
    <QuestionRender ref="qr" v-bind="props" @record:completed="onRecordComplete"/>
    <PromptDialog v-bind="prompt" v-if="prompt !== null" />
  </div>
</template>
<script setup>
import { ref, defineProps, computed, reactive, defineExpose, watch , nextTick } from "vue";
import { storeToRefs } from "pinia";
import {useRoute, useRouter} from 'vue-router'
import QuestionRender from "@/components/Question/QuestionRender.vue";
import PromptDialog from "@/components/Prompt/PromptDialog.vue";
import { useAnswerInstanceStore } from "@/stores/answerInstance-store";
import { onMounted } from "vue";
import { useTimer } from "@/composables/useTimer";
import { useExamInstanceStore } from "@/stores/examInstance-store";
import { mockPromise } from "@/utils/utils";
const route = useRoute();
const router = useRouter();
const mode = (route.query.mode);
const props = defineProps({
  moduleData: {
    type: Object,
    required: true,
  },
  payload: {
    type: Object,
    required: true,
  },
  question: {
    type: Object,
    required: true,
  },
  examId: {
    required: true,
  },
  examType: {
    required: true,
    default: () => 'mock'
  },
  isLastQuestion: {
    required: true,
    default: () => false
  },
  freeze: {
    required: true,
    default: () => false
  },
});
const emit = defineEmits(["handleSubmit"]);
const prompt = ref(null);
const qr = ref("")
const recorderSlugs = ['ra', 'rs', 'di', 'rl', 'asq', ]
const playerSlugs = ['rs', 'rl', 'asq', 'sst', 'wfd', 'hcs', 'mcs-l', 'mcm-l', 'smw', 'hiw', 'fib-l']
const { initializeTimer, timeEnded } = useTimer();
const answerInstanceStore = useAnswerInstanceStore();
const { setQuestionId, resetAnswer } = answerInstanceStore;
const { answer } = storeToRefs(answerInstanceStore);
const slug = computed(() => {
  return props.question.topic_slug;
});
resetAnswer();
setQuestionId(props.question.id);

const onRecordComplete = async () => {
  if(props.freeze) {
    return false
  }
  if(mode === "next" || props.examType === 'test') {
    return;
  }
  prompt.value = {
    title: "Recording Stopped!",
    content: '',
    actions: [
      {
        text: "Next",
        type: "fill",
        onClick: () => {
          emit('handleSubmit')
        },
      },
    ],
  };
};
const handleTimeEnded = () => {
  if(props.freeze) {
    return false
  }
  prompt.value = {
    title: "Time's Up",
    content:"Please click <strong>“Next”</strong> to go to the next question",
    actions: [
      {
        text: "Next",
        type: "fill",
        onClick: () => emit('handleSubmit'),
      },
    ],
  };
};
watch(timeEnded, (newVal, oldVal) => {
  if (newVal) {
    handleTimeEnded();
  }
});
const validate = () => {
  let result = true
  // if(slug.value === 'ro') {
  //     const orderedLength = (answer.value.answer_list || []).length
  //     if(orderedLength && props.question.options.length !== orderedLength) {
  //       result = {
  //         message: "All list should be ordered"
  //       }
  //     }
  //   }
  // }
  // else if(['mcm-r', 'mcm-l'].includes(slug.value)) {
  //   if(!answer.value.answer_list || !Array.isArray(answer.value.answer_list) || answer.value.answer_list.length === 0) {
  //     result = {
  //       message: "Please select atleast one value"
  //     }
  //   }
  // }
  if(playerSlugs.includes(slug.value)) {
    if(!qr.value.playerStopped) {
      result = {
        message: "Audio is playing"
      }
    }
  }
  if(result !== true) {
    result.err = true
  }
  return result;
}
const wrapUpForSubmit = async () => {
  if(recorderSlugs.includes(slug.value)){
    return await qr.value.stopRecorder();
  }
  return mockPromise()
}
defineExpose({
  wrapUpForSubmit,
  validate
})
</script>
